import { isUserLoggedIn, getNewAuthRoute } from '@/auth/utils';
import { canNavigate } from '@/libs/acl/routeProtection';
import { getDomain } from '@/store/auth/auth.store';
import Vue from 'vue';
import store from '@/store/index';
import i18n from '@/libs/i18n';

export default async function authGuard(to, _, next) {
  let communityId;
  let nextAltered = false;
  let navigateToMyNectios = false;
  // if (document.referrer.indexOf('appsumo.com') > -1) {
  //   console.log('from appsumo');
  // }
  const spaceSlug = process.env.VUE_APP_SPACE_SLUG || 'explore';

  console.log('authGuard - to', to);

  to.params.communitySlug ? communityId = to.params.communitySlug : communityId = to.params.communityId;
  
  console.log('authGuard - communityId', communityId);
  if(!communityId || communityId === 'undefined' || communityId === ''){
    console.log('authGuard - communityId is undefined, accessing myNectios login');
    navigateToMyNectios = true;
    // next();
  } else if(communityId === 'error'  || typeof communityId !== 'string'){
    console.log('authGuard - communityId is not a valid community slug, setting communityId to global');
    communityId = process.env.VUE_APP_SPACE_SLUG || 'explore';
    console.log('new communityId', communityId);
    nextAltered = true;
  }
  console.log('authGuard - communityId', communityId);

  const isLoggedIn = isUserLoggedIn();
  console.log('authGuard - isLoggedIn', isLoggedIn);

  if(to.path === '/[object%20Object]'){
    console.log('authGuard - to.path is [object Object], returning...');
    // redirect to login
    window.location.href = `/${spaceSlug}/`;
    return;
  }

  const queryHasToken = to.query.jwttoken;
  console.log('authGuard - queryHasToken', queryHasToken);

  if (!isLoggedIn && queryHasToken) {
    console.log('authGuard - isLoggedIn is false and queryHasToken is true');
    if (to.query.lang) {
      console.log('authGuard - setting locale to', to.query.lang);
      i18n.locale = to.query.lang;
      Vue.$cookies.set('i18n_redirected', i18n.locale);
      store.dispatch('setLocale', i18n.locale);
    }
    console.log('authGuard - dispatching secureAuth');
    await store.dispatch('secureAuth', { token: to.query.jwttoken });

    next();
  }

  if (!canNavigate(to)) {
    console.log('authGuard - canNavigate is false');
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      console.log('authGuard - not logged in, redirecting to login');

      if(navigateToMyNectios){
        console.log('authGuard - navigating to myNectios login');
        return next();
      }

      Vue.$cookies.set('lastUrl', window.location, '1h', '/', getDomain(), true, 'Strict');
      let newAuthRoute = '/';
      
      // Simplified redirect logic
      console.log('authGuard - spaceSlug', spaceSlug);
      newAuthRoute = `/${spaceSlug}/auth/login`;
      
      console.log('authGuard - redirecting to newAuthRoute:', newAuthRoute);
      window.location.href = newAuthRoute;
      return;
    }

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    console.log('authGuard - redirecting to Resumen');
    next({
      name: 'Resumen',
      params: { 
        communityId: communityId 
      },
    });
  }

  console.log('authGuard - checking altered next');
  if(nextAltered){
    console.log('authGuard - nextAltered is true, redirecting to login');
    // Use the same redirection logic as the unauthorized case
    const loginPath = `/${communityId}/auth/login`;
    console.log('authGuard - redirecting to:', loginPath);
    window.location.href = loginPath;
    return;
  }

  console.log('authGuard - returning next');
  return next();
}
