/* eslint-disable */
import CollectiveFilter from "@/views/apps/collectives/constants/CollectiveFilter";
import MediaType from "@/views/apps/media/constants/MediaType";

// console.log('space-routes.js');

export default [
  {
    path: "",
    //name: "space",
    component: () => import("@/views/community/SpaceRoot.vue"  /* webpackChunkName: "SpaceRoot" */),
    //component: () => import("@/backoffice/views/Dashboard.vue")
    redirect: { name: 'Resumen' },
    meta: {
      breadcrumb: [],
    },
    children: [
      // COMMUNITY -> HOME
      {
        path: "",
        name: "Resumen",
        component: () => import("@/views/community/Home.vue"  /* webpackChunkName: "SpaceRoot-Home" */),
        meta: {
          breadcrumb: [],
        },
      },
      // MYSELF -> GLOBAL SETTINGS (PERFIL NEXOS)
      {
        path: "/:communityId/perfil",
        name: "memberProfile",
        component: () => import("@/views/myself/views/MemberProfile.vue"),
        meta: {
          breadcrumb: [],
        },
        props: true
      },
      // COMMUNITY -> WELCOME HALL
      {
        path: '/:communityId/welcome',
        name: 'welcome',
        component: () => import('@/views/community/welcome/Welcome.vue' /* webpackChunkName: "SpaceRoot-Welcome" */),
        meta: { 
          layout: 'horizontal', 
        },
        props: true,
      },
      // COMMUNITY -> ONBOARDING FORM PREVIEW
      {
        path: "onboarding-form",
        name: "onboarding-form",
        component: () =>
          import("@/backoffice/views/forms/components/FormPreview.vue" /* webpackChunkName: "SpaceRoot-FormPreview" */),
        props: true,
        meta: {
          layout: "horizontal",
        },
      },
      // COMMUNITY -> ONBOARDING FORM SUCCESS
      {
        path: "onboarding-success/:subscriptionPlanId",
        name: "onboarding-success",
        component: () => import("@/backoffice/views/onboarding/OnboardingSuccess.vue"),
        props: true,
        meta: {
          layout: "full",
          // pageTitle: 'OnboardingSuccess',
          // breadcrumb: [],
        },
      },
      {
        path: "onboarding-checkout/:type/:id",
        name: "onboarding-checkout",
        component: () => import("@/views/community/onboarding/OnboardingCheckout.vue"),
        props: true,
        meta: {
          // pageTitle: 'OnboardingCheckout',
          // breadcrumb: [],
        },
      },
      {
        path: "onboarding-plans",
        name: "onboarding-plans",
        component: () => import("@/views/community/onboarding/OnboardingPlans.vue"),
        props: true,
        meta: {
          // pageTitle: 'OnboardingPlans',
          // breadcrumb: [],
        },
      },
      // COMMUNITY -> FORM PREVIEW (TODO: pasarlo a una vista separada sin lo del layout)
      {
        path: "forms/:id",
        name: "forms-detail",
        component: () =>
          import("@/backoffice/views/forms/components/FormPreview.vue" /* webpackChunkName: "SpaceRoot-FormPreview" */),
        props: true,
        meta: {
          layout: "horizontal",
        },
      },
      // COMMUNITY -> FORM SUCCESS
      {
        path: "forms/:id/sent",
        name: "forms-success",
        component: () =>
          import("@/backoffice/views/forms/components/FormSuccess.vue" /* webpackChunkName: "SpaceRoot-FormSuccess" */),
        props: true,
        meta: {
          layout: "full",
        },
      },
      // COMMUNITY -> EVENTS
      {
        path: "events",
        name: "events",
        component: () =>
          import(
            "@/views/apps/membershipEvents/components/MembershipEventsList.vue" /* webpackChunkName: "SpaceRoot-MembershipEventsList" */
          ),
        props: true,
        meta: {
          pageTitle: "events.page-title.event-list",
        },
      },
      {
        path: "events/:slug",
        name: "event",
        component: () =>
          import(
            "@/views/apps/membershipEvents/components/MembershipEventDetails"  /* webpackChunkName: "SpaceRoot-MembershipEventDetails" */
          ),
        props: true,
        meta: {
          pageTitle: "events.page-title.event-details",
          goBackAnchor: "events",
        },
      },
      {
        path: "events/:id/room",
        name: "event-room",
        component: () => import("@/views/event-detail/EventDetailsRoom.vue"),
        props: true,
        meta: {
          pageTitle: "events.page-title.event-details-room",
          goBackAnchor: "",
        },
      },
      {
        path: "events/:id/tickets",
        name: "tickets",
        component: () => import("@/views/tickets/TicketsList.vue"),
        props: true,
        meta: {
          pageTitle: "tickets.page-title.tickets-list",
          breadcrumb: [
            {
              text: "tickets.breadcrumb-text.tickets-list",
              active: true,
            },
          ],
        },
      },
      {
        path: "events/:id/tickets/:ticketId",
        name: "ticket",
        component: () => import("@/views/tickets/TicketDetails.vue"),
        props: true,
        meta: {
          pageTitle: "tickets.page-title.tickets-list",
          breadcrumb: [
            {
              text: "tickets.breadcrumb-text.tickets-list",
              active: true,
            },
          ],
        },
      },
      // COMMUNITY -> PRESENTATIONS
      {
        path: "presentations",
        name: "presentations",
        component: () => import("@/views/apps/media/components/MediaList.vue"),
        props: {
          mediaType: MediaType.presentation,
        },
        meta: {
          pageTitle: "presentations.page-title.presentations-list",
          breadcrumb: [
            {
              text: "presentations.breadcrumb-text.presentations-list",
              active: true,
            },
          ],
        },
      },
      {
        path: "presentations/:itemKey",
        name: "presentation-details",
        component: () => import("@/views/apps/media/components/MediaDetails.vue"),
        props: (route) => ({
          itemKey: route.params.itemKey,
          mediaType: MediaType.presentation,
        }),
        meta: {
          pageTitle: "presentations.page-title.presentation-details",
          goBackAnchor: "presentations",
        },
      },
      // COMMUNITY -> DOCUMENTS
      {
        path: "documents",
        name: "documents",
        component: () => import("@/views/apps/media/components/MediaList.vue"),
        props: {
          mediaType: MediaType.document,
        },
        meta: {
          pageTitle: "documents.page-title.documents-list",
          breadcrumb: [
            {
              text: "documents.breadcrumb-text.documents-list",
              active: true,
            },
          ],
        },
      },
      {
        path: "documents/:itemKey",
        name: "document-details",
        component: () => import("@/views/apps/media/components/MediaDetails.vue"),
        props: (route) => ({
          itemKey: route.params.itemKey,
          mediaType: MediaType.document,
        }),
        meta: {
          pageTitle: "documents.page-title.document-details",
          goBackAnchor: "documents",
        },
      },
      {
        path: "collaborative-documents/:itemKey",
        name: "collaborative-documents",
        component: () =>
          import("@/views/apps/media/components/MediaCollaborativeDocuments.vue"),
        props: (route) => ({
          itemKey: route.params.itemKey,
          mediaType: MediaType.document,
        }),
        meta: {
          pageTitle: "media.collaborative-breadcrumb",
          goBackAnchor: "documents",
        },
      },
      // COMMUNITY -> SPREADSHEETS
      {
        path: "spreadsheets",
        name: "spreadsheets",
        component: () => import("@/views/apps/media/components/MediaList.vue"),
        props: {
          mediaType: "spreadsheet",
        },
        meta: {
          pageTitle: "spreadsheets.page-title.spreadsheets-list",
          breadcrumb: [
            {
              text: "spreadsheets.breadcrumb-text.spreadsheets-list",
              active: true,
            },
          ],
        },
      },
      {
        path: "spreadsheets/:itemKey",
        name: "spreadsheet-details",
        component: () => import("@/views/apps/media/components/MediaDetails.vue"),
        props: (route) => ({
          itemKey: route.params.itemKey,
          mediaType: "spreadsheet",
        }),
        meta: {
          pageTitle: "documents.page-title.document-details",
          goBackAnchor: "document-list",
        },
      },
      // COMMUNITY -> AUDIOS
      {
        path: "audios",
        name: "audios",
        component: () => import("@/views/apps/media/components/MediaList.vue"),
        props: {
          mediaType: MediaType.audio,
        },
        meta: {
          pageTitle: "audios.page-title.audios-list",
          breadcrumb: [
            {
              text: "audios.breadcrumb-text.audios-list",
              active: true,
            },
          ],
        },
      },
      {
        path: "audios/:itemKey",
        name: "audio-details",
        component: () => import("@/views/apps/media/components/MediaDetails.vue"),
        props: (route) => ({
          itemKey: route.params.itemKey,
          mediaType: MediaType.audio,
        }),
        meta: {
          pageTitle: "audios.page-title.audio-details",
          goBackAnchor: "audios",
        },
      },
      // COMMUNITY -> PHOTOS
      {
        path: "photos",
        name: "photos",
        component: () => import("@/views/apps/media/components/MediaList.vue"),
        props: {
          mediaType: MediaType.photo,
        },
        meta: {
          pageTitle: "photos.page-title.photos-list",
          breadcrumb: [
            {
              text: "photos.breadcrumb-text.photos-list",
              active: true,
            },
          ],
        },
      },
      {
        path: "photos/:itemKey",
        name: "photo-details",
        component: () => import("@/views/apps/media/components/MediaDetails.vue"),
        props: (route) => ({
          itemKey: route.params.itemKey,
          mediaType: MediaType.photo,
        }),
        meta: {
          pageTitle: "photos.page-title.audio-details",
          goBackAnchor: "photos",
        },
      },
      {
        path: "gallery",
        name: "gallery",
        component: () => import("@/views/apps/media/GalleryDetails.vue"),
        props: true,
        meta: {
          pageTitle: "gallery.page-title.gallery-details",
          breadcrumb: [
            {
              text: "gallery.breadcrumb-text.gallery-details",
              active: true,
            },
          ],
        },
      },
      // COMMUNITY -> VIDEOS
      {
        path: "videos",
        name: "videos",
        component: () => import("@/views/apps/media/components/MediaList.vue"),
        props: {
          mediaType: MediaType.video,
        },
        meta: {
          pageTitle: "videos.page-title.videos-list",
          breadcrumb: [
            {
              text: "videos.breadcrumb-text.videos-list",
              active: true,
            },
          ],
        },
      },
      {
        path: "videos/:itemKey",
        name: "video-details",
        component: () => import("@/views/apps/media/components/MediaDetails.vue"),
        props: (route) => ({
          itemKey: route.params.itemKey,
          mediaType: MediaType.video,
        }),
        meta: {
          pageTitle: "videos.page-title.video-details",
          goBackAnchor: "videos",
        },
      },
      // COMMUNITY -> PEOPLE
      {
        path: "people/:typeKey",
        name: "people",
        component: () => import("@/views/apps/people/components/PeopleDetails.vue"),
        props: true,
        meta: {
          pageTitle: "people.page-title.people-details", // TODO: implement title and breadbcrumb per :type
          goBackAnchor: ($route) => `people-${$route.params.typeKey}`,
        },
      },
      // COMMUNITY -> ROOMS
      {
        path: "rooms",
        name: "rooms",
        component: () => import("@/views/rooms-all/RoomsAll.vue"),
        props: true,
        meta: {
          pageTitle: "rooms.page-title.rooms-list",
          breadcrumb: [
            {
              text: "rooms.breadcrumb-text.rooms-list",
              active: true,
            },
          ],
        },
      },
      {
        path: "rooms/:name",
        name: "room",
        component: () => import("@/views/apps/rooms/components/RoomsDetails.vue"),
        props: true,
        meta: {
          pageTitle: "rooms.page-title.room-details",
          goBackAnchor: "rooms",
        },
      },
      {
        path: "auditorium",
        name: "auditorium",
        component: () => import("@/views/apps/auditorium/Auditorium.vue"),
        props: true,
        meta: {
          pageTitle: "Auditorum",
          active: true,
          goBackAnchor: "Auditorium",
        },
      },
      // COMMUNITY -> STREAMING
      {
        path: "streaming",
        name: "streaming",
        component: () =>
          import("@/views/apps/streaming/components/StreamingDetails.vue"),
        props: true,
        meta: {
          pageTitle: "streaming.page-title",
          breadcrumb: [
            {
              text: "streaming.breadcrumb-text",
              active: true,
            },
          ],
        },
      },
      // COMMUNITY -> MEMBERS
      {
        path: "members",
        name: "members",
        component: () => import("@/views/apps/member/MembersList.vue"),
        props: true,
        meta: {
          pageTitle: "members.page-title.members-list",
        },
      },
      {
        path: "members/:username",
        name: "member-details",
        component: () => import("@/views/apps/member/MemberDetails.vue"),
        props: true,
        meta: {
          pageTitle: "members.page-title.member-details",
          goBackAnchor: "members",
        },
      },
      // COMMUNITY -> CONTACTS
      {
        path: "contacts",
        name: "contacts",
        component: () => import("@/views/apps/contacts/ContactsList.vue"),
        props: true,
        meta: {
          pageTitle: "contacts.page-title.contacts-list",
          breadcrumb: [
            {
              text: "contacts.breadcrumb-text.contacts-list",
              active: true,
            },
          ],
        },
      },
      // COMMUNITY -> POSTS
      {
        path: "posts",
        name: "posts",
        component: () => import("@/views/apps/posts/PostsList.vue"),
        props: true,
        meta: {
          pageTitle: "posts.page-title.posts-list",
        },
      },
      {
        path: "posts/:postKey",
        name: "posts-details",
        component: () => import("@/views/apps/posts/components/PostsDetails.vue"),
        props: true,
        meta: {
          pageTitle: "posts.page-title.post-details",
          breadcrumb: [
            {
              text: "posts.breadcrumb-text.posts-list",
              to: { name: "posts" },
            },
            {
              text: "posts.breadcrumb-text.post-details",
              active: true,
            },
          ],
        },
      },
      // COMMUNITY -> ORGANIZATIONS
      {
        path: "organizations",
        name: "organizations",
        component: () =>
          import("@/views/organizations-list/OrganizationsList.vue"),
        props: true,
        meta: {
          pageTitle: "organizations.page-title.organizations-list",
        },
      },
      {
        path: "organizations?type=:type",
        name: "organizations-type",
        component: () =>
          import("@/views/organizations-list/OrganizationsList.vue"),
        props: true,
        meta: {
          pageTitle: "organizations.page-title.organizations-list",
        },
      },
      {
        path: "organizations/:slug",
        name: "organization",
        component: () =>
          import("@/views/organization-detail/OrganizationsDetails.vue"),
        props: true,
        meta: {
          pageTitle: "organizations.page-title.organization",
          goBackAnchor: "organizations",
        },
      },
      // COMMUNITY -> SOCIETIES
      {
        path: "societies",
        name: "societies",
        component: () => import("@/views/apps/societies/components/SocitiesList.vue"),
        props: true,
        meta: {
          pageTitle: "societies.page-title.societies-list",
        },
      },
      {
        path: "societies?type=:type",
        name: "societies-type",
        component: () => import("@/views/apps/societies/components/SocitiesList.vue"),
        props: true,
        meta: {
          pageTitle: "societies.page-title.societies-list",
        },
      },
      {
        path: "societies/:organizationId",
        name: "societies-details",
        component: () => import("@/views/apps/societies/components/SocietyDetails.vue"),
        props: true,
        meta: {
          pageTitle: "societies.page-title.society-details",
          goBackAnchor: "societies",
        },
      },
      // COMMUNITY -> STORAGE
      {
        path: "storage/:folderKey?",
        name: "storage",
        component: () =>
          import("@/views/apps/storage/components/StorageList.vue"),

        props: {
          mediaType: MediaType.storage,
        },
        meta: {
          pageTitle: "storage.page-title.storage-list",
          breadcrumb: [
            {
              text: "storage.breadcrumb-text.storage-list",
              active: true,
            },
          ],
        },
      },
      {
        path: "storage/file/:itemKey",
        name: "storage-details",
        component: () => import("@/views/apps/media/components/MediaDetails.vue"),
        props: (route) => ({
          itemKey: route.params.itemKey,
          mediaType: MediaType.storage,
        }),
        meta: {
          pageTitle: "storage.breadcrumb-text.storage-details",
          goBackAnchor: "storage",
        },
      },
      // COMMUNITY -> AGENDA
      {
        path: "agenda",
        name: "agenda",
        component: () => import("@/views/events-list/EventsList.vue"),
        props: true,
        meta: {
          pageTitle: "schedule.page-title",
          breadcrumb: [
            {
              text: "schedule.breadcrumb-text",
              active: true,
            },
          ],
        },
      },
      // COMMUNITY -> ENTITIES
      {
        path: "entity/:typeKey",
        name: "entity",
        component: () => import("@/views/apps/entities/EntityDetails.vue"),
        props: true,
        meta: {
          pageTitle: "entities.pageTitle", // TODO: implement title and breadbcrumb per :entityType
          goBackAnchor: ($route) => `entities-${$route.params.entityType}`,
        },
      },
      // COMMUNITY -> SECTIONS (is space)
      {
        path: "sections",
        name: "sections",
        component: () => import("@/views/collectives-list/CollectivesList.vue"),
        props: {
          collectiveFilter: CollectiveFilter.section,
        },
        meta: {
          pageTitle: "collectives.sections.page-title.sections-list",
        },
      },
      // COMMUNITY -> COURSES (is space)
      {
        path: "courses",
        name: "courses",
        component: () =>
          import("@/views/apps/courses/components/CoursesList.vue"),
        props: {
          collectiveFilter: CollectiveFilter.course,
        },
        meta: {
          pageTitle: "collectives.courses.page-title.courses-list",
        },
      },
      {
        path: "courses/:slug",
        name: "course",
        component: () =>
          import("@/views/apps/courses/components/CourseDetails"),
        props: true,
        meta: {
          pageTitle: "collectives.courses.page-title.courses-details",
          goBackAnchor: "courses",
        },
      },
      // COMMUNITY -> SUBCOMMUNITIES (is space)
      {
        path: "subcommunities",
        name: "subcommunities",
        component: () => import("@/views/collectives-list/CollectivesList.vue"),
        props: {
          collectiveFilter: CollectiveFilter.subcommunity,
        },
        meta: {
          pageTitle:
            "collectives.subcommunities.page-title.subcommunities-list",
        },
      },
      {
        path: "subcommunities/:slug",
        name: "subcommunity",
        component: () => import("@/views/apps/subcommunities/components/SubcommunityDetails.vue"),
        props: true,
        meta: {
          pageTitle:
            "collectives.subcommunities.page-title.subcommunities-list",
        },
      },
      // COMMUNITY -> FAQS
      {
        path: "faqs",
        name: "faqs",
        component: () => import("@/views/apps/faqs/Faq.vue"),
        props: true,
        meta: {
          pageTitle: "faqs.title",
          breadcrumb: [
            {
              text: "faqs.breadcrumb-text",
              active: true,
            },
          ],
        },
      },
      // COMMUNITY -> TASKS
      {
        path: "tasks",
        name: "tasks",
        component: () => import("@/views/apps/tasks/components/TasksList.vue"),
        props: true,
        meta: {
          pageTitle: "tasks.page-title.task-list",
        },
      },
      {
        path: "tasks/:id",
        name: "task-details",
        props: true,
        component: () =>
          import("@/views/apps/tasks/components/TaskDetails.vue"),
        meta: {
          pageTitle: "tasks.page-title.task-details",
        },
      },
      // COMMUNITY -> PRODUCTS
      {
        path: "products/:id",
        name: "product",
        component: () =>
          import("@/views/apps/products/components/ProductDetails.vue"),
        props: true,
        meta: {
          pageTitle: "products.page-title.product-details",
          goBackAnchor: "products",
        },
      },
      {
        path: "products",
        name: "products",
        component: () =>
          import("@/views/apps/products/components/ProductsList.vue"),
        props: true,
        meta: {
          pageTitle: "products.page-title.product-list",
        },
      },
      // COMMUNITY -> GRANTS
      {
        path: "grants",
        name: "grants",
        component: () =>
          import("@/views/apps/grants/components/GrantsList.vue"),
        props: true,
        meta: {
          pageTitle: "grants.page-title.grant-list",
          breadcrumb: [
            {
              text: "grants.breadcrumb-text.grant-list",
              active: true,
            },
          ],
        },
      },
      {
        path: "grants/:id",
        name: "grant",
        component: () =>
          import("@/views/apps/grants/components/GrantDetails.vue"),
        props: true,
        meta: {
          pageTitle: "grants.page-title.grants-details",
          goBackAnchor: "grants",
        },
      },
      // COMMUNITY -> SERVICES (is space)
      {
        path: "services",
        name: "services",
        component: () =>
          import("@/views/apps/services/components/ServicesList.vue"),
        props: true,
        meta: {
          pageTitle: "services.page-title.service-list",
        },
      },
      {
        path: "services/:slug",
        name: "service",
        component: () =>
          import("@/views/apps/services/components/ServiceDetails.vue"),
        props: true,
        meta: {
          pageTitle: "services.page-title.service-details",
          goBackAnchor: "services",
        },
      },
      // COMMUNITY -> CHALLENGES (is space)
      {
        path: "challenges",
        name: "challenges",
        component: () =>
          import("@/views/apps/challenges/components/ChallengesList.vue"),
        props: true,
        meta: {
          pageTitle: "challenges.page-title.challenge-list",
        },
      },
      {
        path: "challenges/:slug",
        name: "challenge",
        component: () =>
          import("@/views/apps/challenges/components/ChallengeDetails.vue"),
        props: true,
        meta: {
          pageTitle: "challenges.page-title.challenge-details",
          goBackAnchor: "challenges",
        },
      },
      // COMMUNITY -> PROJECTS (is space)
      {
        path: "projects",
        name: "projects",
        component: () =>
          import("@/views/apps/projects/components/ProjectsList.vue"),
        props: true,
        meta: {
          pageTitle: "projects.page-title.project-list",
        },
      },
      {
        path: "projects/:slug",
        name: "project",
        component: () =>
          import("@/views/apps/projects/components/ProjectDetails.vue"),
        props: true,
        meta: {
          pageTitle: "projects.page-title.project-details",
          goBackAnchor: "projects",
        },
      },
      // COMMUNITY -> SUCCESS STORIES
      {
        path: "success-stories",
        name: "success-stories",
        component: () =>
          import(
            "@/views/apps/success-stories/components/SuccessStoriesList.vue"
          ),
        props: true,
        meta: {
          pageTitle: "successstories.page-title.successstorie-list",
        },
      },
      {
        path: "success-stories/:id",
        name: "successstory",
        component: () =>
          import(
            "@/views/apps/success-stories/components/SuccessStoriesDetails.vue"
          ),
        props: true,
        meta: {
          pageTitle: "successstorie.page-title.successstorie-details",
          goBackAnchor: "successstories",
        },
      },
      // COMMUNITY -> IDEAS
      {
        path: "ideas",
        name: "ideas",
        component: () => import("@/views/apps/ideas/components/IdeasList.vue"),
        props: true,
        meta: {
          pageTitle: "ideas.title",
          breadcrumb: [
            {
              text: "ideas.title",
              active: true,
            },
          ],
        },
      },
      // COMMUNITY -> FORUMS
      {
        path: "forums",
        name: "forums",
        component: () => import("@/views/apps/forums/components/ForumList.vue"),
        props: true,
        meta: {
          pageTitle: "forums.page-title.forum-list",
        },
      },
      {
        path: "forums/:id",
        name: "forum",
        component: () =>
          import("@/views/apps/forums/components/ForumDetails.vue"),
        props: true,
        meta: {
          pageTitle: "forums.page-title.forum-details",
          goBackAnchor: "forums",
        },
      },
      // COMMUNITY -> QUESTIONS
      {
        path: "questions",
        name: "questions",
        component: () =>
          import("@/views/apps/questions/components/QuestionsList.vue"),
        props: true,
        meta: {
          pageTitle: "questions.page-title.question-list",
        },
      },
      // COMMUNITY -> MAP
      {
        path: "map",
        name: "map",
        component: () =>
          import("@/views/apps/locations/components/LocationsGeneralView.vue"),

        meta: {
          pageTitle: "maps.title",
          breadcrumb: [
            {
              text: "maps.title",
              active: true,
            },
          ],
        },
      },
      // COMMUNITY -> CHAT
      {
        path: "chat",
        name: "chat",
        component: () => import("@/views/apps/chatv2/components/ChatList.vue"),
        props: true,
        meta: {
          pageTitle: "Chat",
        },
      },
      // COMMUNITY -> BLOGS
      {
        path: "blogs",
        name: "blogs",
        component: () => import("@/views/apps/blogs/components/BlogList.vue"),
        props: true,
        meta: {
          pageTitle: "blogs.page-title.blog-list",
        },
      },
      {
        path: "blogs/:id",
        name: "blog",
        component: () =>
          import("@/views/apps/blogs/components/BlogDetails.vue"),
        props: true,
        meta: {
          pageTitle: "blogs.page-title.blog-details",
          goBackAnchor: "blogs",
        },
      },
      // COMMUNITY -> VOTES
      {
        path: "votes",
        name: "votes",
        component: () => import("@/views/votes/Votes.vue"),
        props: true,
        meta: {
          pageTitle: "votes.page-title.vote-list",
          breadcrumb: [
            {
              text: "votes.breadcrumb-text.votes-list",
              active: true,
            },
          ],
        },
      },
      {
        path: "votes/:id",
        name: "votesResults",
        component: () => import("@/views/votes/VotesResults.vue"),
        props: true,
        meta: {
          pageTitle: "votes.page-title.vote-details",
          goBackAnchor: "votes",
        },
      },
      // COMMUNITY -> ARTICLES
      {
        path: "articles",
        name: "articles",
        component: () =>
          import("@/views/apps/articles/components/ArticleList.vue"),
        props: true,
        meta: {
          pageTitle: "articles.page-title.article-list",
        },
      },
      {
        path: "articles/:id",
        name: "article",
        component: () =>
          import("@/views/apps/articles/components/ArticleDetails.vue"),
        props: true,
        meta: {
          pageTitle: "articles.page-title.article-details",
          goBackAnchor: "articles",
        },
      },
      {
        path: "articles/new",
        name: "articlesCreate",
        component: () =>
          import("@/views/apps/articles/components/ArticleCreate.vue"),
        props: true,
        meta: {
          pageTitle: "articles.page-title.article-list",
          breadcrumb: [
            {
              to: { name: "articles" },
              text: "articles.breadcrumb-text.articles-list",
            },
            {
              text: "articles.create-title",
              active: true,
            },
          ],
        },
      },
      // COMMUNITY -> JOBS
      {
        path: "jobs",
        name: "jobs",
        component: () => import("@/views/apps/jobs/components/JobsList.vue"),
        props: true,
        meta: {
          pageTitle: "jobs.page-title.job-list",
        },
      },
      {
        path: "jobs/:id",
        name: "job",
        component: () => import("@/views/apps/jobs/components/JobDetails.vue"),
        props: true,
        meta: {
          pageTitle: "jobs.page-title.job-details",
          goBackAnchor: "jobs",
        },
      },
      // COMMUNITY -> COUPONS
      {
        path: "coupons/:id",
        name: "coupons",
        component: () =>
          import("@/views/apps/coupons/components/CouponForm.vue"),
        props: true,
        meta: {
          pageTitle: "coupons.title-detail",
          breadcrumb: [
            {
              text: "coupons.title-detail",
              active: true,
            },
          ],
        },
      },
      {
        path: "coupons-form/:id",
        name: "coupons-bo",
        component: () => import("@/backoffice/views/CouponForm.vue"),
        props: true,
        meta: {
          pageTitle: "coupons.title-detail",
          breadcrumb: [
            {
              text: "coupons.title-detail",
              active: true,
            },
          ],
        },
      },
      // COMMUNITY -> ABOUT
      {
        path: "about",
        name: "about",
        component: () => import("@/views/apps/about/About.vue"),
        props: true,
        meta: {
          pageTitle: "about.title",
          goBackAnchor: "about",
        },
      },
      // COMMUNITY -> ORDERS
      {
        path: "order/:type/:id",
        name: "orderPurchase",
        component: () => import("@/views/apps/orders/OrderPurchase.vue"),
        props: true,
        meta: {
          pageTitle: "Orders",
          breadcrumb: [
            {
              text: "Orders",
              active: true,
            },
          ],
        },
      },
      // COMMUNITY -> SUCCESS TICKETS ?
      {
        path: "success/tickets",
        name: "SuccessTickets",
        component: () => import("@/views/tickets/SuccessTickets.vue"),
        meta: {
          breadcrumb: [],
        },
      }
    ]
  }
];
