export default {
  install(Vue) {
    Vue.prototype.$updateMetaTags = function(config) {
      // Update title
      document.title = config.title || process.env.VUE_APP_TITLE || '';
      
      // Update meta description
      const descriptionTag = document.querySelector('meta[name="description"]');
      if (descriptionTag) {
        descriptionTag.setAttribute('content', config.description || process.env.VUE_APP_DESCRIPTION || '');
      }

      // Update meta keywords
      const keywordsTag = document.querySelector('meta[name="keywords"]');
      if (keywordsTag) {
        keywordsTag.setAttribute('content', config.keywords || process.env.VUE_APP_KEYWORDS || '');
      }

      // Update Twitter meta tags
      const twitterTitle = document.querySelector('meta[name="twitter:title"]');
      if (twitterTitle) {
        twitterTitle.setAttribute('content', config.title || process.env.VUE_APP_TITLE || '');
      }

      const twitterDesc = document.querySelector('meta[name="twitter:description"]');
      if (twitterDesc) {
        twitterDesc.setAttribute('content', config.description || process.env.VUE_APP_DESCRIPTION || '');
      }

      // Update OpenGraph meta tags
      const ogTitle = document.querySelector('meta[property="og:title"]');
      if (ogTitle) {
        ogTitle.setAttribute('content', config.title || process.env.VUE_APP_TITLE || '');
      }

      const ogDesc = document.querySelector('meta[property="og:description"]');
      if (ogDesc) {
        ogDesc.setAttribute('content', config.description || process.env.VUE_APP_DESCRIPTION || '');
      }
    };
  }
}; 