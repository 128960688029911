export default [
  {
    path: "",
    component: () => import("@/my-nectios/MyNectiosRoot.vue"),
    meta: { 
      requiresAuth: true,
      layout: "horizontal",
    },
    children: [
      {
        path: "",
        name: "Nectios",
        component: () => import("@/my-nectios/views/MySpaces.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "my/settings",
        name: "my-settings",
        component: () => import("@/my-nectios/views/MySettings.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      // {
      //   path: "my/referrals",
      //   name: "my-referrals",
      //   component: () => import("@/my-nectios/views/MyReferrals.vue"),
      //   meta: {
      //     breadcrumb: [],
      //   },
      // },
      // {
      //   path: "my/subscription",
      //   name: "myself-subscription",
      //   component: () =>
      //     import("@/my-nectios/views/MySubscriptions.vue"),
      //   meta: {
      //     breadcrumb: [],
      //   },
      // },
      {
        //path: "my/spaces/:spaceSlug/plans",
        path: "my/plans/:community",
        name: "my-plans",
        component: () => import("@/my-nectios/views/subscription/Plans.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "my/checkout/:community/:id",
        name: "my-checkout",
        component: () => import("@/my-nectios/views/subscription/Checkout.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "my/success/:community/:id",
        name: "my-success",
        component: () => import("@/my-nectios/views/subscription/Success.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "my/error",
        name: "my-error",
        component: () => import("@/my-nectios/views/subscription/Error.vue"),
        meta: {
          breadcrumb: [],
        },
      },
    ],
  },
  // when going to "/my" we redirect to MyNectios root:
  {
    path: "my",
    redirect: "/",
    meta: {},
  },
  // {
  //   path: "my/*",
  //   redirect: "/",
  //   meta: {},
  // },
  {
    path: "",
    component: () => import("@/auth/AuthRoot.vue"),
    meta: { 
      requiresAuth: false,
      layout: "horizontal",
    },
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("@/auth/views/Login.vue"),
        meta: {
          // breadcrumb: [],
          redirectIfLoggedIn: true,
          layout: "full",
        },
      },
    ],
  },
];
