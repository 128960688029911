import Vue from 'vue';
import VueRouter from 'vue-router';
import myNectiosRoutes from './routes/mynectios-routes';
import spaceRoutes from './routes/space-routes';
import backofficeRoutes from './routes/backoffice-routes';
import myselfRoutes from './routes/myself-routes';
import authRoutes from './routes/auth-routes';
import legalRoutes from './routes/legal-routes';
import authGuard from './guards/auth-guard';
import errorRoutes from './routes/error-routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    // savedPosition is only available for popstate navigations.
    if (savedPosition) return savedPosition;

    // if the returned position is falsy or an empty object,
    // will retain current scroll position.
    if (to.params.savePosition) return {};

    // scroll to anchor by returning the selector
    if (to.hash) {
      const position = { selector: to.hash };

      // specify offset of the element
      // if (to.hash === '#anchor2') {
      //   position.offset = { y: 100 }
      // }
      return position;
    }

    // scroll to top by default
    return { x: 0, y: 0 };
  },
  routes: [
    // PUBLIC APP STORE (NO AUTH)
    {
      path: "/apps",
      component: () => import("@/my-nectios/views/apps/AppsRoot.vue"),
      children: [
        {
          path: "",
          name: "apps",
          component: () => import("@/my-nectios/views/apps/Apps.vue"),
          meta: {
            layout: "full", // horizontal
          },
        },
        {
          path: ":id",
          name: "app",
          props: true,
          component: () => import("@/my-nectios/views/apps/AppDetails.vue"),
          meta: {
            breadcrumb: [],
            layout: "full",
          },
        },
      ],
    },
    // PUBLIC EXPLORE (NO AUTH)
    {
      path: "/spaces-de-momento-solo-para-probar",
      component: () => import("@/my-nectios/views/explore/ExploreRoot.vue"),
      children: [
        {
          path: "",
          name: "spaces",
          component: () => import("@/my-nectios/views/explore/Spaces.vue"),
          meta: {
            layout: "full", // horizontal
          },
        },
        {
          path: ":slug",
          name: "space",
          props: true,
          component: () => import("@/my-nectios/views/explore/SpaceDetails.vue"),
          meta: {
            breadcrumb: [],
            layout: "full",
          },
        },
      ],
    },
    // error
    {
      path: '/error',
      component: () => import("@/views/error/Error.vue"),
      meta: {
        layout: "full",
      },
    },
    // MY NECTIOS (AUTH)
    ...myNectiosRoutes,
    // COMMUNITY (AUTH) [deprecated]
    ...authRoutes,
    ...legalRoutes,
    // QR ROUTE
    {
      path: '/QR/:communityId/:username',
      name: 'qr',
      component: () => import("@/views/qr/QRPage.vue" /* webpackChunkName: "QRPage" */),
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/:communityId",
      component: () => import("@/views/MainRoot.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [
        ...errorRoutes,
        // BACKOFFICE - The place where the staff administrate the space
        ...backofficeRoutes,
        // MYSELF - Member Account
        ...myselfRoutes,
        // FRONTOFFICE - Space: The place where members interact
        ...spaceRoutes,
        // COMMUNITY -> MAIN WELCOME
        // {
        //   path: '/:communityId/welcome',
        //   name: 'welcome',
        //   component: () => import('@/views/community/welcome/Welcome.vue' /* webpackChunkName: "SpaceRoot-Welcome" */),
        //   meta: { 
        //     layout: 'horizontal', 
        //   },
        //   props: true,
        // },
        // TODO: Create welcomeError page for users who make a mistake in the url, warning of their error
        {
          path: "*",
          redirect: "/:communityId/welcome",
          meta: {},
        },
      ],
    },
    // ERROR ROUTE
    {
      path: '*',
      redirect: 'error',
    },
  ],
});

router.beforeEach(authGuard);

export default router;
