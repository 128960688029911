export default [
  {
    path: "/:communityId/terms-and-conditions",
    name: "terms",
    component: () => import("@/views/legal/TermsConditions/TermsConditions.vue"),
    meta: {
      redirectIfLoggedIn: false,
      layout: "full",
    },
  },
  {
    path: "/:communityId/privacy-policy",
    name: "privacy-policy",
    component: () => import("@/views/legal/PrivacyPolicy/PrivacyPolicy.vue"),
    meta: {
      redirectIfLoggedIn: false,
      layout: "full",
    },
  },
  {
    path: "/:communityId/cookies-policy",
    name: "cookies-policy",
    component: () => import("@/views/legal/CookiesPolicy/CookiesPolicy.vue"),
    meta: {
      redirectIfLoggedIn: false,
      layout: "full",
    },
  },
  {
    path: "/:communityId/legal-center/:type?",
    name: "legal-center",
    component: () => import("@/views/legal/LegalTexts.vue"),
    meta: {
      redirectIfLoggedIn: false,
      layout: "full",
    },
  }
];
