export default [
  
  {
    path: '/:communityId/auth',
    component: () => import('@/auth/AuthRoot.vue'),
    children: [
      {
        path: 'sso-login',
        name: 'SSO Auth',
        component: () => import('@/auth/views/SSOAuth.vue'),
        props: true,
      },
      {
        path: "login",
        name: "auth-login",
        component: () => import("@/auth/views/Login.vue"),
        meta: {
          redirectIfLoggedIn: true,
          layout: "full",
        },
      },
      {
        path: "register",
        name: "auth-register",
        component: () => import("@/auth/views/Register.vue"),
        meta: {
          redirectIfLoggedIn: true,
          layout: "full",
        },
      },
      {
        path: "signup",
        name: "auth-signup",
        component: () => import("@/auth/views/Signup.vue"),
        meta: {
          redirectIfLoggedIn: true,
          layout: "full",
        },
      },
      {
        path: "forgot-password",
        name: "auth-forgot",
        component: () => import("@/auth/views/ForgotPassword.vue"),
        meta: {
          redirectIfLoggedIn: false,
          layout: "full",
        },
      },
      {
        path: "reset-password",
        name: "auth-reset",
        component: () => import("@/auth/views/ResetPassword.vue"),
        meta: {
          redirectIfLoggedIn: false,
          layout: "full",
        },
      },
      {
        path: "logout",
        name: "auth-logout",
        component: () => import("@/auth/views/Logout.vue"),
        meta: {
          redirectIfLoggedIn: false,
          layout: "full",
        },
      },
      {
        path: 'verification',
        name: 'auth-verification',
        component: () => import('@/auth/views/SignupVerification.vue'),
        meta: {
          layout: 'full',
          resource: 'Auth',
          action: 'read'
        }
      }
    ]
  },
  // Keep these routes outside the community scope
  {
    path: '/linkedinCallback',
    name: 'Linkedin ballback',
    component: () => import('@/auth/views/linkedinAuth.vue'),
    props: true,
  }
];
  