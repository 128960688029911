import Vue from 'vue';
import Service from '@/config/service-identifiers';
import $services from '@/config/container';
import router from '@/router';
import { NOTIFICATIONS_ACTIONS, NOTIFICATIONS_GETTERS } from '@/store/notifications/notifications-store-constants';
import { AccessTokenKey } from '@/store/auth/auth.store.constants';

export function getDomain() {
  let domain = window.location.host.split('.').splice(-2).join('.');
  if (domain.startsWith('localhost:')) {
    domain = null;
  }

  return domain;
}

export default {
  state: {},
  getters: {
    token() {
      return Vue.$cookies.get(AccessTokenKey);
    },
    isAuthenticated(_state, { token }) {
      return !!token;
    },
  },
  actions: {
    async login(_ctx, { email, password, communitySlug }) {
      const response = await $services[Service.BackendClient].post('login', {
        email,
        password,
        communitySlug,
      });
      Vue.$cookies.set(AccessTokenKey, response.data, '1y', '/', getDomain(), true, 'Strict');
    },
    async loginsoap(_ctx, { user, password, communitySlug }) {
      const response = await $services[Service.BackendClient].post('loginsoap', {
        user,
        password,
        communitySlug,
      });
      Vue.$cookies.set(AccessTokenKey, response.data, '1y', '/', getDomain(), true, 'Strict');
    },
    async signup(_ctx, {
      email, communitySlug, referralType, referralID,
    }) {
      const response = await $services[Service.BackendClient].post('registeremail', {
        email,
        communitySlug,
        referralType,
        referralID,
      });
      return response;
    },
    async register(_ctx, {
      name, surname, password, token, communitySlug, invited, modality, timezone,
    }) {
      const response = await $services[Service.BackendClient].post('register', {
        name,
        surname,
        password,
        invited,
        token,
        modality,
        communitySlug,
        timezone,
      });
      Vue.$cookies.set(AccessTokenKey, response.data, '1y', '/', getDomain(), true, 'Strict');
      return response;
    },
    async password(_ctx, { email, communitySlug }) {
      const response = await $services[Service.BackendClient].post('password', {
        email,
        communitySlug,
      });
      return response;
    },
    async recoverPassword(_ctx, {
      password, repeatPassword, communitySlug, token,
    }) {
      const response = await $services[Service.BackendClient].post('recoverpassword', {
        password,
        repeatPassword,
        communitySlug,
        token,
      });
      return response;
    },
    async secureAuth(_ctx, { token }) {
      const response = await $services[Service.BackendClient].post('secureAuth', {
        secureToken: token,
      });
      Vue.$cookies.set(AccessTokenKey, response.data, '1y', '/', getDomain(), true, 'Strict');
    },
    async googleAuth(_ctx, { code }) {
      const response = await $services[Service.BackendClient].post('googleauth', {
        code,
      });
      Vue.$cookies.set(AccessTokenKey, response.data, '1y', '/', getDomain(), true, 'Strict');
    },
    async linkedinAuth(_ctx, { code }) {
      const response = await $services[Service.BackendClient].post('googleauth', {
        code,
        linkedin: true,
      });
      Vue.$cookies.set(AccessTokenKey, response.data, '1y', '/', getDomain(), true, 'Strict');
    },
    async SSOAuth(_ctx, { code }) {
      Vue.$cookies.set(AccessTokenKey, code, '1y', '/', getDomain(), true, 'Strict');
    },
    async logout({ dispatch, commit, rootGetters }) {
      try {
        console.log('Auth Store - Starting logout process');
        let communitySlug;
        
        // Get community information
        const currentCommunity = rootGetters.currentCollective;
        const mainCommunity = rootGetters.mainCollective;
        
        console.log('Logout - Community Info:', {
          currentCommunity,
          mainCommunity,
          isSubcommunity: !!mainCommunity // If mainCommunity exists, we're in a subcommunity
        });

        // Determine which slug to use for redirect
        if (mainCommunity?.slug) {
          // If we have a mainCommunity, we're in a subcommunity, so redirect to main
          communitySlug = mainCommunity.slug;
          console.log('In subcommunity - redirecting to main community:', communitySlug);
        } else if (currentCommunity?.slug) {
          // If no mainCommunity but have currentCommunity, we're in a main community
          communitySlug = currentCommunity.slug;
          console.log('In main community - redirecting to current community:', communitySlug);
        } else {
          // Fallback if no community information is available
          console.log('No community information found, redirecting to default auth...');
          window.location.href = process.env.VUE_APP_AUTH;
          return;
        }

        // Clear cookies with proper error handling
        try {
          console.log('Clearing cookies and session data...');
          Vue.$cookies.remove(AccessTokenKey, '/', getDomain());
          
          const cookiesToRemove = ['accessToken', 'registeredTo', 'lastUrl', 'community'];
          cookiesToRemove.forEach(cookieName => {
            Vue.$cookies.keys().forEach(cookie => {
              if (cookie === cookieName) {
                Vue.$cookies.remove(cookie);
                console.log(`Removed cookie: ${cookieName}`);
              }
            });
          });
        } catch (cookieError) {
          console.error('Error clearing cookies:', cookieError);
          // Continue with logout even if cookie clearing fails
        }

        // Handle Nexos specific logic
        if (communitySlug !== 'nexos') {
          const authRedirectUrl = process.env.VUE_APP_AUTH + communitySlug;
          console.log('Redirecting to:', authRedirectUrl);
          window.location.href = authRedirectUrl;
        }

        // Attempt router navigation
        try {
          await router.replace({ 
            name: 'auth-login', 
            params: { communityId: communitySlug } 
          });
        } catch (routerError) {
          console.warn('Router navigation warning:', routerError);
          // Continue with logout even if navigation fails
        }

        // Reset application state
        commit('app/resetApp');
        console.log('Logout process completed successfully');

      } catch (error) {
        console.error('Critical error during logout:', error);
        // Fallback to basic logout if something goes wrong
        Vue.$cookies.remove(AccessTokenKey, '/', getDomain());
        window.location.href = process.env.VUE_APP_AUTH;
      }
    },
  },
};
