export default [
  {
    path: 'error/:error?',
    name: 'error',
    component: () => import('@/views/error/Error.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false
    }
  }
];